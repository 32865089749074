import { endpoints } from '@/api/endpoints'

const auth = endpoints.use('auth')

export default {
  doLoginWithUserPW: (context, payload) => new Promise((resolve, reject) => {
    // context.commit('AUTH_REQUEST');
    setTimeout(() => {
      auth.userPWLogin(payload)
        .then(response => {
          if (response.status !== 422) {
            context.commit('SET_AUTHENTICATED', true)
            context.commit('SET_RUN_SESSION', true)

            const currentTime = new Date()
            const expireTime = new Date(currentTime.getTime() + (18 * 60 * 1000))

            context.commit('SET_AUTH_TIMESTAMP', {
              timeLoggedIn: currentTime,
              timeToExpire: expireTime
            })

            context.commit('SET_CURRENT_USER', response.data.result)
          }

          resolve(response.data)
        }).catch(err => {
          reject(err.response)
        })
    }, 2000)
  }),

  doLoginWithGoogle: (context) => new Promise((resolve, reject) => {
    // context.commit('AUTH_REQUEST');
    const newWindow = openWindow('', 'message')

    setTimeout(() => {
      auth.googleLogin()
        .then((response) => {
          const urlAccountSelection = response.data + '&prompt=select_account'

          newWindow.location.href = urlAccountSelection
          context.commit('SET_AUTHENTICATED', false)
          resolve(response)
        })
        .catch((err) => {
          reject(err.response)
        })
    }, 2000)
  }),

  verifyOTP: (context, payload) => new Promise((resolve, reject) => {
    setTimeout(() => {
      auth.verifyOTP(payload).then((response) => {
        resolve(response.data)
      }).catch((err) => {
        reject(err)
      })
    }, 2000)
  }),

  doRequestPasswordReset: (context, payload) => new Promise((resolve, reject) => {
    setTimeout(() => {
      auth.requestPasswordReset(payload)
        .then(response => {
          resolve(response.data)
        }).catch(err => {
          reject(err.response)
        })
    }, 2000)
  }),

  doUpdatePassword: (context, payload) => new Promise((resolve, reject) => {
    setTimeout(() => {
      auth.updatePassword(payload)
        .then(response => {
          resolve(response.data)
        }).catch(err => {
          reject(err.response)
        })
    }, 2000)
  }),

  retrieveUserSignature: ({ commit }, payload) => new Promise((resolve, reject) => {
    auth.retrieveUserSignature(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  validateMachine: ({ commit }, payload) => new Promise((resolve, reject) => {
    auth.validateMachine(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  resetAuthentication: ({ commit }) => {
    commit('SET_AUTHENTICATED', false)
    commit('SET_OTP_PASSED', false)
    commit('SET_RUN_SESSION', false)
    commit('SET_CURRENT_USER', null)
    commit('SET_AUTH_TIMESTAMP', {
      timeLoggedIn: '',
      timeToExpire: ''
    })
  }
}

function openWindow (url, title, options = {}) {
  if (typeof url === 'object') {
    options = url
    url = ''
  }

  options = { url, title, width: 600, height: 720, ...options }

  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top
  const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width
  const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height

  options.left = ((width / 2) - (options.width / 2)) + dualScreenLeft
  options.top = ((height / 2) - (options.height / 2)) + dualScreenTop

  const optionsStr = Object.keys(options).reduce((acc, key) => {
    acc.push(`${key}=${options[key]}`)
    return acc
  }, []).join(',')

  const newWindow = window.open(url, title, optionsStr)

  if (window.focus) {
    newWindow.focus()
  }

  return newWindow
}
