export default {
  isLoading: false,
  isAuthenticated: false,
  isOTPpassed: false,
  runSession: false,
  currUser: null,
  authTimestamp: {
    timeLoggedIn: '',
    timeToExpire: ''
  },
  snackbar: {
    isOpen: false,
    title: '',
    text: '',
    color: ''
  }
}
