import store from '@/store'
import sha256 from 'crypto-js/sha256'
import CryptoJS from 'crypto-js'

const SHARED_KEY = process.env.VUE_APP_PRE_SHARED_KEY || ''
const susi = 'wlK7ZRNOBimj74oV9bqowfVPSqq+QD6t1YRwVWQMsHA='

export const createSignature = (payload, timestamp) => {
  if (SHARED_KEY && timestamp) {
    if (payload) {
      if (typeof payload === 'object') {
        payload = JSON.stringify(payload)
      }

      return sha256(timestamp + SHARED_KEY + payload).toString()
    } else {
      return sha256(timestamp + SHARED_KEY).toString()
    }
  }

  return ''
}

export const aesEncrypt = (string) => {
  return CryptoJS.AES.encrypt(string, susi).toString()
}

export const aesDecrypt = (string) => {
  return CryptoJS.AES.decrypt(string, susi).toString(CryptoJS.enc.Utf8)
}

export const renderToast = (color, title, text) => {
  store.commit('SET_SNACKBAR', {
    isOpen: true,
    title,
    text,
    color
  })
}
