<template>
  <v-snackbar
    min-width="300px"
    v-model="snackbar.isOpen"
    :color="snackbar.color"
    multi-line
  >
    <b>{{ snackbar.title }}</b>
    <p>{{ snackbar.text }}</p>

    <template v-slot:action="{ attrs }">
      <v-btn
        color="default"
        text
        v-bind="attrs"
        @click="snackbar.isOpen = false"
      >
        Dismiss
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SnacbarView',
  computed: {
    ...mapGetters({
      snackbar: 'snackbar'
    })
  }
}
</script>
