import api from '@/api'
import gcp from '@/api/gcp'

export default {
  googleLogin () {
    return gcp.get('/api/login/google')
  },
  userPWLogin (payload) {
    return api.post('v1/maintenance/api/login/user', payload)
  },
  verifyOTP (payload) {
    return api.post('v1/maintenance/api/otp/usp-otp-validate', payload)
  },
  requestPasswordReset (payload) {
    return api.post('v1/maintenance/api/reset-password/reset-pw', payload)
  },
  updatePassword (payload) {
    return api.post('v1/maintenance/api/reset-password/verify-reset-pw', payload)
  },
  retrieveUserSignature (payload) {
    return api.post('/v1/maintenance/api/user-signature-search', payload)
  },
  validateMachine (payload) {
    return api.get(`/v1/maintenance/api/device/validate?browser=${payload.browser}&key=${payload.key}`)
  }
}
