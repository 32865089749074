<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
    <LoadingScreen />
    <Snackbar />
  </v-app>
</template>

<script>
import LoadingScreen from '@/components/LoadingScreen.vue'
import Snackbar from '@/components/Snackbar.vue'

export default {
  name: 'App',
  components: {
    LoadingScreen,
    Snackbar
  }
}
</script>

<style>
  @import url('assets/fonts/font.css');
  @import url('css/ph-default.css');
</style>
