import axios from 'axios'
import store from '@/store'
import CryptoJS from 'crypto-js'
import { env } from '@/api/config'

const susi = 'wlK7ZRNOBimj74oV9bqowfVPSqq+QD6t1YRwVWQMsHA='

const http = axios.create({
  baseURL: CryptoJS.AES.decrypt(env.get('gcpBaseURL'), susi).toString(CryptoJS.enc.Utf8),
  headers: {
    Accept: 'application/json',
    // 'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + CryptoJS.AES.decrypt(env.get('token'), susi).toString(CryptoJS.enc.Utf8)
  }
})

http.interceptors.request.use((config) => {
  store.commit('SET_LOADING', true)
  return config
})

http.interceptors.response.use(
  response => {
    store.commit('SET_LOADING', false)
    return response
  },
  err => {
    store.commit('SET_LOADING', false)
    return err
  }
)

export default http
