<template>
  <div class="ph-loader" :class="{'show': isLoading}">
    <div class="container">
      <div class="line line1" />
      <div class="line line2" />
      <div class="line line3" />
      <div class="line line4" />
      <div class="line line5" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LoadingView',
  computed: {
    ...mapGetters({
      isLoading: 'isLoading'
    })
  }
}
</script>
<style lang="scss" scoped>
//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future
.ph-loader.opaque {
  background-color: rgba(26, 39, 145, 1) !important;
}

.ph-loader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 10000;
  transition: opacity .2s, visibility .2s;
  opacity: 0;
  visibility: hidden;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  .container {
    padding: 0;
    width: 75px;
    min-height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    .line {
      height: 5px;
      background: gold;
      position: relative;
      transform: skew(-30deg, 0deg);
      opacity: 0;

      &.line1 {
        width: 70%;
        right: -10px;
        animation: animate 2s infinite;
      }

      &.line2 {
        width: 50%;
        animation: animate 2s .1s infinite;
      }

      &.line3 {
        width: 90%;
        animation: animate 2s .2s infinite;
      }

      &.line4 {
        width: 80%;
        animation: animate 2s .3s infinite;
      }

      &.line5 {
        width: 100%;
        animation: animate 2s .4s infinite;
      }
    }
  }
}

@keyframes animate {
  0% {
    transform: translateX(-100%) skew(-30deg, 0deg);
    opacity: 0;
  }

  50% {
    transform: translateX(0%) skew(-30deg, 0deg);
    opacity: 1;
  }

  100% {
    transform: translateX(100%) skew(-30deg, 0deg);
    opacity: 0;
  }
}
</style>
