export default {
  SET_LOADING: (state, payload) => {
    state.isLoading = payload
  },
  SET_AUTHENTICATED: (state, payload) => {
    state.isAuthenticated = payload
  },
  SET_OTP_PASSED: (state, payload) => {
    state.isOTPpassed = payload
  },
  SET_RUN_SESSION: (state, payload) => {
    state.runSession = payload
  },
  SET_CURRENT_USER: (state, payload) => {
    state.currUser = payload
  },
  SET_AUTH_TIMESTAMP: (state, payload) => {
    state.authTimestamp = {
      ...payload
    }
  },
  SET_SNACKBAR: (state, payload) => {
    state.snackbar = {
      ...payload
    }
  }
}
