<template>
  <div></div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'IndexView',
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
      isOTPpassed: 'isOTPpassed'
    })
  },
  created () {
    const userData = this.$cookies.get('USP_DATA')
    const userToken = this.$cookies.get('USP_TOKEN')

    if (this.isAuthenticated) {
      if (!this.isOTPpassed) {
        this.$router.push('/verify-otp-code')
      } else {
        if (userData && userToken) {
          location.href = this.$uspMainAppURL
        } else {
          this.$store.dispatch('resetAuthentication')
          this.$router.push('/login')
        }
      }
    } else {
      this.$store.dispatch('resetAuthentication')
      this.$router.push('/login')
    }
  }
}
</script>
